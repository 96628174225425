import { HttpClient } from '@angular/common/http';
import { UserInfo } from '@core/models/interfaces/auth';
import { AuthService } from '@core/services/auth.service';
import { Store } from '@ngrx/store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsService } from 'ngx-permissions';
import { PrimeNGConfig } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { flattenPermissionCodes } from '@shared/utils/flattenPermissionCodes.util';
import { AppState } from '@state/app.state';
import { setAuthState } from '@state/auth/auth.actions';
import { Router } from '@angular/router';

export const initialFactory = (store: Store<AppState>, authService: AuthService, primeConfig: PrimeNGConfig) => {
  primeConfig.ripple = true;
  const accessToken = authService.getLocalToken();
  let localUser: UserInfo | undefined;
  try {
    localUser = JSON.parse(authService.getLocalUser() as string);
  } catch (error) {
    localUser = undefined;
  }

  if (!localUser || !accessToken) {
    return () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      store.dispatch(setAuthState({ userInfo: undefined, isLoggedIn: false, accessToken: '' }));
    };
  } else {
    return () => {
      store.dispatch(setAuthState({ userInfo: localUser, isLoggedIn: true, accessToken }));
    };
  }
};

export function permissionFactory(authService: AuthService, ps: NgxPermissionsService, router: Router) {
  return async () => {
    if (authService.getLocalToken()) {
      return lastValueFrom(authService.getMyPermission())
        .then(res => {
          if (res.data) {
            const permissions = flattenPermissionCodes(res.data);
            ps.loadPermissions(permissions);
          }
        })
        .catch(() => {
          router.navigate(['login']);
          localStorage.clear();
        });
    }
  };
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { TreeNode } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';

import { ProductDetailsGroupBys } from '@core/constants/report';
import { IdName } from '@core/models/interfaces/common';
import { Company } from '@core/models/interfaces/company';
import { Product } from '@core/models/interfaces/product';
import { ProductType } from '@core/models/interfaces/product-type';
import { ProductDetailsReportParams } from '@core/models/interfaces/report';
import { UserSelect } from '@core/models/interfaces/user';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';

@Component({
  selector: 'app-production-details-selection',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MultiSelectModule,
    CalendarModule,
    FormsModule,
    CommonModule,
    TreeSelectModule,
    TranslateModule,
    DropdownModule,
    FormControlComponent,
    ButtonComponent,
    CustomCalendarComponent
  ],
  templateUrl: './production-details-selection.component.html',
  styleUrl: './production-details-selection.component.scss'
})
export class ProductionDetailsSelectionComponent implements OnInit {
  @Input() isRunning: boolean = false;
  @Input() params: ProductDetailsReportParams;
  @Input() commissionTeams: IdName[] = [];
  @Input() companies: Company[] = [];
  @Input() productTypes: ProductType[] = [];
  @Input() products: Product[] = [];
  @Input() users: UserSelect[] = [];
  @Input() sources: TreeNode[] = [];
  @Input() policyStatuses: TreeNode[] = [];

  @Output() paramsChange = new EventEmitter<ProductDetailsReportParams>();
  @Output() isRunningChange = new EventEmitter<boolean>();

  readonly translatePrefix = 'report.';
  readonly ProductDetailsGroupBys = ProductDetailsGroupBys;

  productionDetailsForm = this.fb.group({
    // OBJECTIVE SELECTION
    commissionTeams: this.fb.control<string[]>(['']),
    // FIELD SELECTION
    sources: this.fb.control<TreeNode[]>([{ label: '' }]),
    // FIELD SELECTION
    policyStatuses: this.fb.control<TreeNode[]>([{ label: '' }]),
    companies: this.fb.control<string[]>(['']),
    productTypes: this.fb.control<string[]>(['']),
    productNames: this.fb.control<string[]>(['']),
    commissionOwners: this.fb.control<string[]>(['']),
    commissionAgents: this.fb.control<string[]>(['']),
    groupBy: this.fb.control<string | null>(null, [Validators.required])
  });

  createdDates: Array<Date | undefined> = [];
  effectiveDates: Array<Date | undefined> = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.params) {
      this.productionDetailsForm.patchValue({
        commissionTeams: this.params.commissionTeamIds,
        sources: this.params.leadSources as TreeNode[],
        policyStatuses: this.params.statuses as TreeNode[],
        companies: this.params.companyIds,
        productTypes: this.params.productTypeIds,
        productNames: this.params.productIds,
        commissionOwners: this.params.commissionOwnerIds,
        commissionAgents: this.params.commissionAgentIds,
        groupBy: this.params.groupBy
      });
      this.createdDates = [
        this.params.createdFrom ? new Date(this.params.createdFrom) : undefined,
        this.params.createdTo ? new Date(this.params.createdTo) : undefined
      ].filter(item => !!item);
      this.effectiveDates = [
        this.params.effectiveFrom ? new Date(this.params.effectiveFrom) : undefined,
        this.params.effectiveTo ? new Date(this.params.effectiveTo) : undefined
      ].filter(item => !!item);
    }
  }

  ngOnChanges() {
    if (this.users.length === 1) {
      this.productionDetailsForm.controls.commissionOwners.disable();
      this.productionDetailsForm.controls.commissionAgents.disable();
    }
  }

  get productTypeOptions() {
    const companyIds = this.productionDetailsForm.value.companies?.filter(item => !!item) as string[];

    if (companyIds?.length) {
      return this.productTypes.filter(item => companyIds.includes(item.company?.id));
    } else {
      return this.productTypes;
    }
  }

  get productOptions() {
    const productTypeIds = this.productionDetailsForm.value.productTypes?.filter(item => !!item) as string[];

    if (productTypeIds?.length) {
      return this.products.filter(item => productTypeIds.includes(item.productType?.id));
    } else {
      return this.products;
    }
  }

  getCommissionTeamsValue(value: IdName[]) {
    return value?.map(item => item.name).join(', ');
  }

  getTreeNodeValue(value: TreeNode[]) {
    return value
      ?.filter(item => !!item.label)
      ?.map(item => item.label)
      .join(', ');
  }

  getCompaniesValue(value: Company[]) {
    return value?.map(item => item.name).join(', ');
  }

  getProductTypesValue(value: ProductType[]) {
    return value?.map(item => item.name).join(', ');
  }

  getProductsValue(value: Product[]) {
    return value?.map(item => item.name).join(', ');
  }

  getCommissionsValue(value: UserSelect[]) {
    if (this.users.length === 1) {
      return this.users[0].fullName;
    } else if (this.users.length > 1) {
      return value?.map(item => item.fullName).join(', ');
    }
    return '';
  }

  onChangeCompany() {
    this.productionDetailsForm.patchValue({
      productTypes: [],
      productNames: []
    });
  }

  onChangeProductType() {
    this.productionDetailsForm.patchValue({
      productNames: []
    });
  }

  onReset() {
    this.productionDetailsForm.patchValue({
      // OBJECTIVE SELECTION
      commissionTeams: [''],
      // FIELD SELECTION
      sources: [{ label: '' }],
      // FIELD SELECTION
      policyStatuses: [{ label: '' }],
      companies: [''],
      productTypes: [''],
      productNames: [''],
      commissionOwners: [''],
      commissionAgents: [''],
      groupBy: null
      // DATE
    });
    this.createdDates = [];
    this.effectiveDates = [];
  }

  onRunReport() {
    const value = this.productionDetailsForm.value;

    this.isRunning = true;
    this.isRunningChange.emit(this.isRunning);

    const effectiveFrom = this.effectiveDates?.[0]
      ? moment(this.effectiveDates?.[0]).startOf('date').toDate().toISOString()
      : undefined;
    const effectiveTo = this.effectiveDates?.[1]
      ? moment(this.effectiveDates?.[1]).startOf('date').toDate().toISOString()
      : undefined;
    const createdFrom = this.createdDates?.[0]
      ? moment(this.createdDates?.[0]).startOf('date').toDate().toISOString()
      : undefined;
    const createdTo = this.createdDates?.[1]
      ? moment(this.createdDates?.[1]).startOf('date').toDate().toISOString()
      : undefined;

    this.params = {
      commissionTeamIds: value.commissionTeams || [],
      leadSources: value.sources || [],
      statuses: value.policyStatuses || [],
      companyIds: value.companies || [],
      productTypeIds: value.productTypes || [],
      productIds: value.productNames || [],
      commissionOwnerIds: value.commissionOwners || [],
      commissionAgentIds: value.commissionAgents || [],
      groupBy: value.groupBy || '',

      effectiveFrom,
      effectiveTo,
      createdFrom,
      createdTo
    };
    this.paramsChange.emit(this.params);
  }

  get sourcesSelectedCount(): number {
    const selectedItems = this.productionDetailsForm.get('sources')?.value || [];
    const leafItems = selectedItems.filter((item) => item && item.leaf);
    return Array.isArray(leafItems) ? leafItems.length : 0;
  }

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, exhaustMap, from, map, of, tap } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { flattenPermissionCodes } from '@shared/utils/flattenPermissionCodes.util';
import {
  login,
  loginFailed,
  loginSuccess,
  otpRequired,
  verifyOtp,
  verifyOtpFailed,
  verifyOtpSuccess
} from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private authService: AuthService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(login),
      exhaustMap(action => {
        return from(this.authService.login(action.email, action.password)).pipe(
          map(res => {
            if (res.data.isOTPSent) {
              return otpRequired({ email: action.email });
            }

            const permissions = flattenPermissionCodes(res.data.userInfo.permissions);
            this.ngxPermissionsService.loadPermissions(permissions);

            localStorage.setItem('accessToken', res.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.userInfo));
            localStorage.setItem('lang', JSON.stringify(res.data.userInfo.language));

            return loginSuccess({
              accessToken: res.data.token,
              userInfo: res.data.userInfo
            });
          }),
          catchError(error => of(loginFailed({ error: error?.error?.code })))
        );
      })
    )
  );

  verifyOTP$ = createEffect(() =>
    this.action$.pipe(
      ofType(verifyOtp),
      exhaustMap(action => {
        return from(this.authService.verifyOTP(action.otp, action.email)).pipe(
          map(res => {
            const permissions = flattenPermissionCodes(res.data.userInfo.permissions);
            this.ngxPermissionsService.loadPermissions(permissions);

            localStorage.setItem('accessToken', res.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.userInfo));
            localStorage.setItem('lang', JSON.stringify(res.data.userInfo.language));

            return verifyOtpSuccess({
              accessToken: res.data.token,
              userInfo: res.data.userInfo
            });
          }),
          catchError(error => {
            return of(verifyOtpFailed({ error: error.error?.code }));
          })
        );
      })
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(loginSuccess, verifyOtpSuccess),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    {
      dispatch: false
    }
  );
}

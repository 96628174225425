<div class="h-13 px-5 flex items-center border-b bg-white fixed top-0 left-0 right-0 z-50">
  <!-- LOGO APP -->
  <div class="flex-1 flex items-center gap-2">
    <i *ngIf="isSmallScreen" class="pi pi-bars p-1 cursor-pointer hover:text-blue-500" (click)="setSidebar()"></i>
    <div class="h-9 flex items-center justify-start pl-[2px]">
      @if (logo.data?.attachment) {
        <app-custom-image [imgId]="logo.data?.attachment || ''" alt="logo" styleClass="h-9"></app-custom-image>
      } @else {
        <img class="h-9 w-9" [src]="environment.LOGO" alt="logo" />
      }
    </div>
    <h1 class="font-semibold text-base">{{ logo.data?.title }}</h1>
  </div>

  <!-- SEARCH BAR -->
  <!-- <div class="flex-1 flex justify-center">
    <div class="h-8 w-[400px] bg-neutral-200 rounded-sm flex items-center px-3 gap-2">
      <i class="icon-wl-search text-5 text-neutral-600"></i>
      <input class="flex-1 outline-0 bg-transparent" [placeholder]="'action.search' | translate" type="text" />
      <div class="flex gap-1 text-neutral-600 text-2">
        <div class="bg-neutral-300 px-[6px] py-[2px] rounded">Ctrl</div>
        <div class="bg-neutral-300 px-[6px] py-[2px] rounded">K</div>
      </div>
    </div>
  </div> -->

  <!-- RIGHT ITEM -->
  <div class="flex-1 flex gap-1 justify-end">
    <div class="flex items-center gap-1">
      <!-- <app-flags></app-flags> -->

      <!-- CALENDAR -->
      <div class="w-9 h-9 flex items-center justify-center">
        <i class="icon-wl-calendar text-6"></i>
      </div>

      <!-- NOTIFICATION -->
      <app-notification></app-notification>

      <app-flags #flags></app-flags>

      <div>
        <div
          class="flex items-center cursor-pointer gap-1 py-[2px] pl-2 rounded-sm hover:bg-neutral-200"
          (click)="visible = !visible">
          <app-custom-avatar size="sm" [imgId]="userInfo.avatar" [name]="userInfo.fullName"></app-custom-avatar>
          <i class="icon-wl-arrow-drop-down text-4"></i>
        </div>
        <p-overlay [(visible)]="visible" appendTo="body">
          <div class="border px-1 py-2 bg-white w-[240px] rounded-sm mr-5">
            <!-- INFO -->
            <div class="border-b flex items-center p-2 gap-2">
              <app-custom-avatar size="lg" [imgId]="userInfo.avatar" [name]="userInfo.fullName"></app-custom-avatar>
              <div class="flex flex-col gap-0.5">
                <span class="font-semibold">{{ userInfo.fullName }}</span>
                <div class="text-neutral-600 text-2">{{ userInfo.position?.name }}</div>
              </div>
            </div>

            <div class="border-b py-2">
              <!-- <div class="flex gap-2 items-center h-9 px-2 cursor-pointer hover:bg-neutral-100 rounded-sm">
                <i class="icon-wl-layout text-6"></i>
                <span>Theme</span>
              </div> -->
              <a
                [routerLink]="'account-setting'"
                class="flex gap-2 items-center h-9 px-2 cursor-pointer hover:bg-neutral-100 rounded-sm"
                (click)="visible = false">
                <i class="icon-wl-setting text-6"></i>
                <span>Account settings</span>
              </a>
            </div>

            <!-- <div class="border-b py-2">
              <div class="flex gap-2 items-center h-9 px-2 cursor-pointer hover:bg-neutral-100 rounded-sm">
                <i class="icon-wl-headphone text-6"></i>
                <span>Contact support</span>
              </div>
              <div class="flex gap-2 items-center h-9 px-2 cursor-pointer hover:bg-neutral-100 rounded-sm">
                <i class="icon-wl-help-circle text-6"></i>
                <span>Help center</span>
              </div>
            </div> -->

            <div
              (click)="onSignOut()"
              class="mt-2 px-2 h-9 text-red-600 flex items-center gap-2 cursor-pointer hover:bg-neutral-100 rounded-sm">
              <i class="icon-wl-log-out text-6"></i>
              <span>Sign out</span>
            </div>
          </div>
        </p-overlay>
      </div>
    </div>
  </div>
</div>

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { Store, provideStore } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpLoaderFactory, initialFactory, permissionFactory } from '@core/config';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { AuthService } from '@core/services/auth.service';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from '@state/auth/auth.effects';
import { authReducer } from '@state/auth/auth.reducers';
import { systemReducer } from '@state/system/system.reducers';
import { taskReducer } from '@state/task/task.reducers';
import { routes } from './app.routes';

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideStore({
      auth: authReducer,
      system: systemReducer,
      task: taskReducer
    }),
    provideEffects(AuthEffects),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initialFactory,
      deps: [Store, AuthService, PrimeNGConfig]
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: permissionFactory,
      deps: [AuthService, NgxPermissionsService, Router]
    },
    importProvidersFrom([TranslateModule.forRoot(provideTranslation())])
  ]
};

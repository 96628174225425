<div class="bg-[#1D2125] min-h-screen">
  @if (isNotFound || (!hasPermissionOpenConfidential && isConfidential)) {
    <app-empty background="notFound" title="This file is not found." titleClass="text-white"></app-empty>
  } @else {
    <div>
      <div class="px-8 h-12 w-full flex justify-between items-center bg-dark-neutral-0">
        <div class="flex gap-2">
          <img appFileIcon [fileType]="file?.type" class="h-5 w-5" />
          <span class="text-white font-medium">{{ file?.originalName }}</span>
        </div>

        @if (file?.type !== TYPE_FILE.PDF) {
          <div
            *ngxPermissionsOnly="Permissions.ATTACHMENT_DOWNLOAD"
            class="w-9 h-9 flex items-center justify-center cursor-pointer hover:bg-white/20 rounded-md transition"
            (click)="onDownloadFile()">
            <i class="icon-wl-download text-6 text-white"></i>
          </div>
        }
      </div>
      <div class="min-h-[calc(100vh_-_48px)] flex items-center justify-center">
        @if (isSupport) {
          @switch (type) {
            @case ('DOCUMENT') {
              @if (viewer === 'pdf') {
                @if (pdfUrl) {
                  <iframe [src]="pdfUrl" width="100%" style="height: calc(100vh - 48px)"></iframe>
                }
              } @else {
                <ngx-doc-viewer [url]="url" [viewer]="viewer" class="h-[calc(100vh_-_48px)] w-full"></ngx-doc-viewer>
              }
            }
            @case ('IMAGE') {
              <img [src]="url" class="max-w-[80vw] mt-5 mb-10" />
            }
            @case ('VIDEO') {
              <video controls class="min-w-[60vw] max-w-[80vw] mx-auto">
                <source [src]="url" type="video/webm" />

                <source [src]="url" type="video/mp4" />
              </video>
            }
          }
        } @else {
          <app-empty background="notFound" title="This file is not supported to preview." titleClass="text-white">
            <div footer>
              <app-button
                icon="icon-wl-download"
                iconPosition="left"
                [label]="'action.download' | translate"
                size="xl"
                (onClick)="onDownloadFile()"></app-button>
            </div>
          </app-empty>
        }
      </div>
    </div>
  }
</div>

import { UserInfo } from '@core/models/interfaces/auth';
import { createReducer, on } from '@ngrx/store';
import {
  login,
  loginFailed,
  loginSuccess,
  logout,
  otpRequired,
  register,
  registerFailed,
  registerSuccess,
  setAuthState,
  updateUserInfoField,
  verifyOtp,
  verifyOtpFailed,
  verifyOtpSuccess
} from './auth.actions';
export interface AuthState {
  accessToken: string;
  error: string;
  isLogging: boolean;
  isRegistering: boolean;
  isLoggedIn: boolean;
  otpRequired: boolean;
  userInfo?: UserInfo;
}

const initialState: AuthState = {
  accessToken: '',
  error: '',
  userInfo: undefined,
  isLogging: false,
  isRegistering: false,
  isLoggedIn: false,
  otpRequired: false
};

export const authReducer = createReducer(
  initialState,
  on(login, state => ({
    ...state,
    error: '',
    isLogging: true
  })),

  on(loginSuccess, (state, { accessToken, userInfo }) => ({
    ...state,
    accessToken,
    userInfo,
    isLogging: false,
    isLoggedIn: true
  })),

  on(loginFailed, (state, { error }) => ({
    ...state,
    error,
    isLogging: false
  })),

  on(otpRequired, state => ({
    ...state,
    otpRequired: true
  })),

  on(verifyOtp, state => ({
    ...state,
    error: '',
    isLogging: true
  })),

  on(verifyOtpSuccess, (state, { accessToken, userInfo }) => ({
    ...state,
    accessToken,
    userInfo,
    isLogging: false,
    isLoggedIn: true
  })),

  on(verifyOtpFailed, (state, { error }) => ({
    ...state,
    error,
    isLogging: false
  })),

  on(register, state => ({
    ...state,
    isRegistering: true
  })),

  on(registerSuccess, state => ({
    ...state,
    isRegistering: false
  })),

  on(registerFailed, (state, { error }) => ({
    ...state,
    error,
    isRegistering: false
  })),

  on(setAuthState, (state, { userInfo, accessToken }) => ({ ...state, userInfo, isLoggedIn: true, accessToken })),

  on(updateUserInfoField, (state, { field, value }) => ({
    ...state,
    userInfo: {
      ...state.userInfo!,
      [field]: value
    }
  })),

  on(logout, state => ({
    ...state,
    isLoggedIn: false,
    userInfo: undefined
  }))
);

<div
  [ngClass]="isMenuShow ? 'w-[16.5rem]' : 'w-6'"
  class="max-xl:hidden min-h-[calc(100vh-52px)] grid gap-1 content-start pr-4 py-3 border-r bg-white transition-all sticky top-13 h-fit z-10">
  @if (isMenuShow) {
    <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
  }

  <i
    (click)="isMenuShow = !isMenuShow"
    class="absolute z-10 top-4 right-0 translate-x-1/2 translate-y-full p-2 text-gray-400 bg-white rounded-full shadow cursor-pointer text-xs"
    [ngClass]="isMenuShow ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"></i>
</div>

<p-sidebar (visibleChange)="setIsSidebar($event)" [visible]="!!(isSidebar$ | async)">
  <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
</p-sidebar>

<ng-template #navigateSection>
  <!-- PARENT -->
  @for (item of Sidebars; track $index) {
    <div *ngxPermissionsOnly="item.permissions || []">
      <a
        [routerLink]="item.children ? null : item.path"
        [routerLinkActive]="
          'text-primary-600 font-semibold bg-primary-100 after:absolute after:left-0 after:h-full after:w-[2px] after:bg-blue-600'
        "
        (click)="onClickItem(item, $index)"
        class="relative flex items-center h-9 pl-5 pr-3 min-w-max font-medium cursor-pointer rounded-r-sm hover:bg-neutral-200">
        <!-- Icon -->
        @if (item.children && isMenuShow) {
          <i
            class="text-neutral-600 icon-wl-arrow-drop-right absolute text-4 top-1/2 -translate-y-1/2 left-[2px] transition"
            [ngClass]="showChildren[$index] ? 'rotate-90' : ''"></i>
        }

        <div class="flex items-center gap-3">
          <i class="{{ item.icon }} text-xl "></i>
          @if (isMenuShow || !!(isSidebar$ | async)) {
            <span>{{ item.title | translate }}</span>
          }
        </div>
      </a>
      <!-- CHILDREN -->
      @if (item.children && showChildren[$index] && (isMenuShow || !!(isSidebar$ | async))) {
        <ul>
          @for (child of item.children; track $index) {
            <a
              *ngxPermissionsOnly="child.permissions || []"
              [routerLinkActive]="'text-primary-600 font-medium bg-primary-100 border-l-primary-600 border-l-blue-600'"
              class="mt-1 h-9 flex items-center rounded-r transition hover:bg-neutral-200 cursor-pointer border-l-2 border-l-transparent"
              [routerLink]="child.path"
              (click)="setIsSidebar(false)">
              <div class="flex items-center h-full">
                <div class="pl-14 w-max">
                  {{ child.title | translate }}
                </div>
              </div>
            </a>
          }
        </ul>
      }
    </div>
  }
</ng-template>
